/**
 * Module dependencies
 */
import React from 'react';
import { shape, string, arrayOf } from 'prop-types';

import TrendsCarousel from '@seo-frontend-components/trends-carousel';

import { useStaticProps } from '../../components/context/static-props';
import { withComponentHandler } from '../../hocs/with-component-handler';

/**
 * @typedef {Object} Trend
 * @property {string} keyword - The keyword for the trend.
 * @property {string} url - The URL for the trend.
 * @property {Array<{url: string, description: string}>} images - The images for the trend.
 * @property {string} description - The description of the trend.
 */

/**
 * @typedef {Object} TrendsCategories
 * @property {string} title - The title of the trends categories.
 * @property {Trend[]} trends - The list of trends.
 */

/**
 * CategoryTrendCarousel Component
 *
 * @param {Object} props - The component props.
 * @param {TrendsCategories} props.trends_categories - The trends categories data.
 * @returns {JSX.Element} The rendered component.
 */

const viewData = {
  trendsToViewMinDefault: 3,
  trendsToViewDefault: 4,
  minWidthToShowAllEntries: 1275,
};

const CategoryTrendCarousel = ({ trends_categories }) => {
  const { deviceType, jsDisabled, webp: supportWebP } = useStaticProps();

  return (
    <TrendsCarousel
      trends_categories={trends_categories}
      deviceType={deviceType}
      jsDisabled={jsDisabled}
      supportWebP={supportWebP}
      trendsToViewMinDefault={viewData.trendsToViewMinDefault}
      trendsToViewDefault={viewData.trendsToViewDefault}
      minWidthToShowAllEntries={viewData.minWidthToShowAllEntries}
      thumbnailSize={48}
    />
  );
};

CategoryTrendCarousel.propTypes = {
  trends_categories: shape({
    title: string.isRequired,
    trends: arrayOf(
      shape({
        description: string.isRequired,
        images: arrayOf(
          shape({
            description: string.isRequired,
            url: string.isRequired,
          }),
        ).isRequired,
        keyword: string.isRequired,
        url: string.isRequired,
      }),
    ),
  }),
};

/**
 * Inject i18n context as props into CategoryTrendCarousel.
 */
export default withComponentHandler(CategoryTrendCarousel, { componentName: 'CategoryTrendCarousel' });
